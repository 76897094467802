// 1- *** LIBRARIES ***
import React from "react";

//  2- **** LOCAL FILES/COMPONENTS ***

//  3- *** STYLING ***
import "./MainHeader.css";

const MainHeader = (props) => {
  return <header className="main-header">{props.children}</header>;
};

export default MainHeader;

import React from "react";

import "./HR.css";

const HR = () => {
  return (
    <>
      <center>
        <hr className="horizentalRow" />
      </center>
    </>
  );
};

export default HR;

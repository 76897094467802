// 1- *** LIBRARIES ***
import React from "react";

//  2- **** LOCAL FILES/COMPONENTS ***

//  3- *** STYLING ***

const Users = () => {
  return <h2>Users Works</h2>;
};

export default Users;
